import { Box, ColorPreset, useTheme, Visibility } from "@gocardless/flux-react";

import { MAX_WIDTH_LG, MAX_WIDTH_SM } from "./constants";
import { HeaderLayout } from "./Headers/HeaderLayout";
import { layoutGutterVStyle } from "./styles";
import { LayoutProps } from "./types";

export interface TwoPanelLeftPrimaryLayoutProps extends LayoutProps {
  leftPanel?: React.ReactNode;
  rightPanel?: React.ReactNode;
}

const MAX_WIDTH_MD = "632px";

const MAX_WIDTH_RIGHT_PANEL = "336px";
const MAX_WIDTH_LEFT_PANEL = "696px";

export const TwoPanelLeftPrimaryLayout: React.FC<
  TwoPanelLeftPrimaryLayoutProps
> = ({ header, leftPanel, rightPanel }) => {
  const { theme } = useTheme();
  return (
    <Box
      bg={ColorPreset.BackgroundLight_02}
      flexDirection="column"
      layout="flex"
      minHeight="100dvh"
    >
      {header && (
        <HeaderLayout progressBar={header.progressBar}>
          {header.content}
        </HeaderLayout>
      )}
      <Box
        maxWidth={[MAX_WIDTH_SM, null, MAX_WIDTH_MD, MAX_WIDTH_LG]}
        width="100%"
        css={[layoutGutterVStyle(theme), { margin: "0 auto" }]}
        flexGrow={1}
      >
        <Box layout="flex" flexDirection={["column", null, null, "row"]}>
          <Box
            flexShrink={0}
            gutterH={[1.5, null, 2, 4]}
            maxWidth={MAX_WIDTH_LEFT_PANEL}
            width="100%"
          >
            {leftPanel}
          </Box>
          <Visibility
            css={{
              width: "100%",
            }}
            visible={["none", null, null, "block"]}
          >
            <Box height="100%" gutterH={4}>
              <Box
                maxWidth={MAX_WIDTH_RIGHT_PANEL}
                height="100%"
                css={{
                  margin: "0 auto",
                }}
              >
                {rightPanel}
              </Box>
            </Box>
          </Visibility>
        </Box>
      </Box>
    </Box>
  );
};
