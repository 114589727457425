import { Box, ColorPreset, Visibility, useTheme } from "@gocardless/flux-react";

import { HeaderLayout } from "./Headers/HeaderLayout";
import { layoutGutterVStyle } from "./styles";
import { MAX_WIDTH_SM } from "./constants";
import { LayoutProps } from "./types";

export interface TwoPanelLayoutProps extends LayoutProps {
  leftPanel?: React.ReactNode;
  rightPanel?: React.ReactNode;
}

const MAX_WIDTH_LG = "1392px";
const MAX_WIDTH_MD = "664px";

export const TwoPanelLayout: React.FC<TwoPanelLayoutProps> = ({
  header,
  leftPanel,
  rightPanel,
}) => {
  const { theme } = useTheme();
  return (
    <Box
      layout="flex"
      flexDirection="column"
      bg={ColorPreset.BackgroundLight_02}
      minHeight="100dvh"
    >
      {header && (
        <HeaderLayout progressBar={header.progressBar}>
          {header.content}
        </HeaderLayout>
      )}
      <Box
        maxWidth={[MAX_WIDTH_SM, null, MAX_WIDTH_MD, MAX_WIDTH_LG]}
        width="100%"
        css={[layoutGutterVStyle(theme), { margin: "0 auto" }]}
        flexGrow={1}
      >
        <Box layout="flex" flexDirection={["column", null, null, "row"]}>
          <Box gutterH={[1.5, null, 3, 4]} width={[null, null, null, "50%"]}>
            {leftPanel}
          </Box>
          <Visibility
            css={{
              flexBasis: "50%",
            }}
            visible={["none", null, null, "block"]}
          >
            <Box height="100%" gutterH={[4]}>
              {rightPanel}
            </Box>
          </Visibility>
        </Box>
      </Box>
    </Box>
  );
};
